<template>
  <div class="books">
    <template v-if="!isAgent && !isAgentUser">
      <ad-mask/>
    </template>

    <announcement/>

    <header>
      <b-card>
        <b-row align-v="center" v-if="!bookType">
          <b-col cols="auto">
            <img class="icon" style="height: 3em"
                 src="https://img.xinshu.me/resource/f6ebf7dd81b44712804777553bee1991"
                 v-if="holidayTitle"/>
            <fa icon="book" fad class="text-primary" size="3x" v-else/>
          </b-col>
          <b-col>
            <h5 class="mb-1">{{holidayTitle ? holidayTitle : '创建新作品'}}</h5>
            <div class="text-muted">
              <span v-if="holidayTitle">圣诞给自己做本书 →</span>
              <span v-else>导入朋友圈、微博、相册等...</span>
            </div>
          </b-col>
          <b-col cols="auto">
            <b-btn variant="primary" to="/create">
              <fa icon="plus-circle"/>
              创建作品
            </b-btn>
          </b-col>
        </b-row>
        <b-row align-v="center" v-else>
          <b-col cols="auto">
            <b-link class="text-body" to="/books" exact>
              <fa icon="chevron-left"/>
              返回
            </b-link>
          </b-col>
          <b-col>
            <h5 class="mb-1 text-center">
              <book-icon muted :value="bookType"/>
              心书 ·
              {{bookTypeName}}
            </h5>
          </b-col>
          <b-col cols="auto">
            <b-link :to="`/create/${bookType}`">
              <fa icon="plus-circle"/>
              新建
            </b-link>
          </b-col>
        </b-row>
      </b-card>
    </header>

    <template v-if="loading">
      <b-row>
        <b-col sm="6" v-for="i in skeCount" :key="'ske' + i">
          <b-card>
            <header class="mb-2" v-if="!bookType">
              <ske type="icon"/>
              <ske type="text" width="5em"/>
            </header>
            <book-item :value="{}">
              <ske type="cover" slot="cover"/>
              <ske type="text" slot="title" width="10em"/>
              <template slot="info">
                <ske type="text" width="6em" block/>
                <ske type="text" width="6em" block/>
              </template>
            </book-item>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <tips class="mb-3" target=".cover" v-if="currentBooks.length">
        <fa class="animation-down-up" fal icon="long-arrow-alt-down"/>
        点击封面开始编辑哦
      </tips>

      <template v-if="currentBooks.length">
        <b-row>
          <b-col sm="6" v-for="book in currentBooks" :key="book.bookId">
            <b-card>
              <header class="mb-2" v-if="!bookType">
                <b-row align-v="center">
                  <b-col style="font-size: 16px;">
                    <book-icon :value="book.bookType" muted/>
                    <b>{{getCategory(book.bookType)}}</b>
                  </b-col>
                  <b-col cols="auto">
                    <span class="text-muted" v-if="book.totalCount === 1">共 1 本</span>
                    <b-link :to="'/books?bookType=' + book.bookType" class="text-body"
                            v-else-if="book.totalCount > 0">
                      查看全部
                      <span class="text-primary">{{book.totalCount}}</span>
                      本
                      <fa icon="chevron-right"/>
                    </b-link>
                    <b-link
                      :to="bookType === 'blogbook' ? `/create/${bookType}/weixinmp` : `/create/${bookType}`"
                      v-else>
                      <fa icon="plus-circle"/>
                      新建一本
                    </b-link>
                  </b-col>
                </b-row>
              </header>

              <book-item :value="book">
                <template slot="title">
                  <b-badge variant="success" v-if="book.isSample">样书</b-badge>
                  <b-badge variant="success" title="使用兑换码创建"
                           v-else-if="book.redeemCode">兑
                  </b-badge>
                  {{book.title}}
                </template>
                <template slot="info" v-if="book.aid">
                  <div class="text-overflow">
                    <b-badge variant="muted">{{book.tid | productName}}</b-badge>
                    <b-badge variant="muted">{{book.innerStyle.name}}</b-badge>
                    <b-badge variant="muted" v-if="book.minPageCount > 0">{{book.pages}}页</b-badge>
                  </div>
                  <div class="small text-overflow">
                    <fa icon="clock" fw/>
                    <datetime :value="book.createdAt" format="YYYY.MM.DD" prefix="创建于 "/>
                  </div>
                  <div class="small text-overflow">
                    <fa icon="image" fw/>
                    已传图 {{book.uploadedImagesCount}}
                  </div>
                </template>

                <template slot="info" v-else-if="book.sid">
                  <div class="text-overflow">
                    <b-badge variant="muted">{{book.typesetName}}</b-badge>
                    <b-badge variant="muted">{{sourceSite[book.sourceSite]}}</b-badge>
                    <b-badge variant="muted">{{book.pages || 0}}页</b-badge>
                  </div>
                  <div class="small text-overflow">
                    <fa icon="clock" fw/>
                    创建于
                    {{book.createdAt | moment('YYYY.MM.DD')}}
                  </div>
                </template>

                <template slot="info" v-else>
                  <div class="text-overflow">
                    <b-badge variant="muted">{{book.typesetName}}</b-badge>
                    <b-badge variant="muted">{{book.pages || 0}}页</b-badge>
                    <b-badge variant="muted">
                      <fa icon="thumbs-up"/>
                      <span v-if="book.usedLikes">{{book.totalLikes - book.usedLikes}} /</span>
                      {{book.totalLikes}}
                    </b-badge>
                  </div>
                  <div class="small text-overflow">
                    <fa icon="clock" fw/>
                    <datetime :value="book.fromDate" format="YYYY.MM" beijing/>
                    -
                    <datetime :value="book.toDate" format="YYYY.MM" beijing/>
                  </div>
                  <template v-if="!book.isSample">
                    <div class="small">
                      <b-link :to="'/books/' + book.bookId + '/share'"
                              v-if="$day().isBetween('2021-12-09', '2021-12-13')">
                        <b-badge variant="primary">1212</b-badge>
                        分享集赞，抵扣低至5折
                      </b-link>
                      <b-link :to="'/books/' + book.bookId + '/share'" v-else>
                        <fa icon="share"/>
                        分享集赞，下单时可抵现哦
                      </b-link>
                    </div>
                    <div class="small" v-if="book.hasComments && book.style.enableComment">
                      <fa icon="comment-alt"/>
                      作品含评论
                    </div>
                  </template>
                </template>

                <template slot="action">
                  <template v-if="book.isSample">
                    <b-btn :to="'/books/' + book.bookId">查看这本样书</b-btn>
                  </template>

                  <template v-else-if="book.finalized">
                    <b-btn :to="'/books/' + book.bookId">预览</b-btn>
                    <b-btn variant="primary" :to="'/books/' + book.bookId + '/buy'">
                      <fa icon="shopping-cart"/>
                      加印
                    </b-btn>
                  </template>

                  <template v-else>
                    <b-dd :split-to="'/books/' + book.bookId + (book.sourceType ? '?write=1' : '')"
                          split :text="book.sourceType ? '写日记' : '编辑'"
                          v-if="!book.tid">
                      <b-dd-item :to="'/books/' + book.bookId + '/edit'">
                        编辑内容
                      </b-dd-item>
                      <b-dd-item :to="'/books/' + book.bookId + '/months'"
                                 v-if="book.bookType !== 'blogbook'">
                        筛选月份
                      </b-dd-item>
                      <b-dd-item :to="'/books/' + book.bookId + '/design'">设计样式</b-dd-item>
                      <b-dd-item v-if="book.bookType === 'blogbook'"
                                 :to="'/books/' + book.bookId + '/catalog?write=1'">
                        新建文章
                      </b-dd-item>
                      <b-dd-item :to="'/books/' + book.bookId + '/update'" v-if="book.canUpdate">
                        更新内容
                      </b-dd-item>
                      <b-dd-divider/>
                      <template v-if="book.bookType !== 'blogbook'">
                        <b-dd-item :to="'/books/' + book.bookId + '/share'">
                          {{book.shared ? '查看分享' : '分享作品'}}
                        </b-dd-item>
                      </template>
                      <b-dd-item @click="deleteBook(book)">删除作品</b-dd-item>
                    </b-dd>

                    <b-dd :split-to="`/albums/${book.aid}`" split text="编辑"
                          v-else-if="book.tid && !book.locked">
                      <b-dd-item :to="'/albums/' + book.aid + '?mode=s'">分享作品</b-dd-item>
                      <b-dd-item @click="cloneAlbum(book)">复制作品</b-dd-item>
                      <b-dd-divider/>
                      <b-dd-item class="text-muted" @click="deleteBook(book)">删除作品</b-dd-item>
                    </b-dd>

                    <b-btn class="ml-2" :to="`/books/${book.bookId}/buy`" variant="primary"
                           v-if="!book.locked">
                      <fa icon="shopping-cart"/>
                      购买
                    </b-btn>

                    <b-btn v-if="book.locked && book.aid" @click="deleteBook(book)">删除作品</b-btn>
                  </template>
                </template>
              </book-item>
            </b-card>
          </b-col>
        </b-row>

        <div class="btn-area" v-if="currentBooks.length < currentBooks.totalCount">
          <loading v-if="scrolling" style="height: 39px;"/>
          <b-btn block @click="loadMore" v-else>共 {{totalCount}} 本，查看更多</b-btn>
        </div>
      </template>

      <b-row v-else>
        <b-col>
          <b-card>
            <empty icon="book">
              <h4>没有相关作品哦</h4>
              <b-link
                :to="bookType === 'blogbook' ? `/create/${bookType}/weixinmp` : `/create/${bookType}`">
                <fa icon="plus-circle"/>
                点击新建一本
              </b-link>
            </empty>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { get } from 'lodash'
import route from '@/mixins/route-data'
import { bookProducts } from '@/config'
import { configShare } from '@/modules/wechat'

export default {
  name: 'books',
  title: '我的书架',
  components: {
    BookItem: require('./Book/BookItem').default,
    AdMask: require('@/components/AdMask').default,
    Announcement: require('@/components/Announcement').default
  },
  mixins: [route('books')],
  data() {
    return {
      page: 1,
      pageSize: 8,
      skeCount: 2,
      creating: false,
      scrolling: false,
      sourceSite: {
        weixinmp: '微信公众号',
        meipian: '美篇',
        jianshu: '简书',
        sina_blog: '新浪博客',
        sohu_blog: '搜狐博客',
        zhihu_blog: '知乎专栏',
        douban: '豆瓣日记',
        lofter: '乐乎',
        xinshu: '原创',
        qq_blog: 'QQ空间日志'
      },
      sampleBooks: require('./Book/Samples')
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.bookType === 'star-wbbook') {
      return location.replace('https://weiboshu.com/my/books')
    }
    if (to.query.bookType === 'album') {
      to.query.bookType = 'albums'
      return next(to)
    }
    next()
  },
  watch: {
    $route: {
      handler(route) {
        this.skeCount = this.$ls.get('ske.' + (route.query.bookType || 'books')) || 2
      },
      immediate: true
    }
  },
  computed: {
    holidayTitle() {
      if (this.$day().isBetween('2021-12-24 00:00', '2021-12-25 23:59:59')) {
        return '圣诞快乐🎄'
      }
      return ''
    },
    currentBooks() {
      if (this.bookType) {
        return this.books
      }

      const bookTypes = [
        'wxbook',
        'albums',
        'wbbook',
        'diarybook',
        'shuoshuo_book',
        'blogbook',
        'qbbbook',
        'bbsbook'
      ]
      let books = []

      for (const type of bookTypes) {
        let book = this.books.find(i => i.bookType === type)
        if (!book && this.sampleBooks[type]) {
          book = {...this.sampleBooks[type], isSample: true}
        }
        if (book) {
          books.push(book)
        }
      }

      books = books.sort((a, b) => {
        return new Date(b.updateTime) - new Date(a.updateTime)
      })

      return books
    },
    totalCount() {
      return this.books.totalCount || this.currentBooks.totalCount
    },
    bookType() {
      return this.$route.query.bookType || ''
    },
    bookTypeName() {
      return this.getCategory(this.bookType)
    }
  },
  methods: {
    onLoad() {
      this.page = 1

      // 清除浏览历史防止直接在此进入设计样式后返回到错误的作品中
      this.$store.state.routeHistory = []

      configShare({
        title: '心书·微信书 - 微信时光一键成书',
        link: 'https://weixinshu.com',
        desc: '还能制作微博书、日记书、照片书哦',
        imgUrl: 'https://static.weixinshu.com/assets/images/logo.png'
      })

      if (!this.$route.query.bookType) {
        // 计算骨架图数量
        this.$ls.set('ske.books', this.currentBooks.length)
        for (const book of this.currentBooks) {
          if (book.totalCount) {
            this.$ls.set('ske.' + book.bookType, Math.min(book.totalCount, 12))
          }
        }
      }
    },
    async loadMore() {
      const lastBook = this.currentBooks[this.currentBooks.length - 2]
      try {
        this.scrolling = true
        const books = await this.fetchBooks({page: ++this.page})
        if (!books.length) {
          this.books.totalCount = this.books.length
          return
        }
        this.books.push(...books)
      } finally {
        this.scrolling = false
        this.$nextTick(() => {
          const el = this.$el.querySelector(`[data-id="${lastBook.bookId}"]`)
          if (el) {
            el.focus()
          }
        })
      }
    },
    getCategory(bookType) {
      return get(bookProducts, [bookType, [0]]) || '照片书'
    },
    async setPublic(book, value) {
      if (!value) {
        const confirmed = await this.$dialog.confirm({
          title: '取消分享',
          content: '取消分享后作品会设为私密，其他人将无法预览内容及点赞'
        })
        if (!confirmed) {
          return
        }
      } else {
        const goShare = await this.$dialog.confirm({
          title: '分享作品',
          content: '您需要先公开作品，分享后其他人才能查看内容哦，您也可后续在目录或书架菜单中取消分享。',
          okTitle: '公开并前往分享',
          cancelTitle: '算了'
        })
        if (!goShare) {
          return
        }
      }

      await this.$req.post('/api/library/edit_share_book', {
        bookId: book.id,
        bookType: book.bookType,
        shared: !!value
      })

      book.shared = !!value

      if (value) {
        this.goShare(book)
      } else {
        this.$alert.success('已将作品设置为私密')
      }
    },
    async cloneAlbum(book) {
      const title = await this.$dialog.prompt({
        title: '复制作品',
        content: '复制后当前作品将会被克隆，所有图片及修改将会被保留。' +
          '<br>请输入作品名称 <b>' + book.title + '</b> 确认',
        placeholder: '作品名称'
      })
      if (!title) {
        return
      }
      if (title.split(/\s/).join('') !== book.title.split(/\s/).join('')) {
        this.$alert.error('作品名称不匹配，如有特殊字符，可以尝试先修改作品名称')
        return
      }
      const res = await this.$req.post('/jianxiang/api/1/albums/', {aid: book.aid, tid: book.tid})
      this.$alert.success('作品已复制')
      this.$router.push('/albums/' + res.aid)
    },
    goto(book) {
      this.$router.push('/books/' + book.bookId)
    },
    async deleteBook(book) {
      if (book.bookType === 'wxbook') {
        this.$dialog.confirm({
          title: '删除微信书',
          content: '微信书暂不支持自助删除，请联系客服处理',
          okTitle: '我知道了',
          okOnly: true
        })
        return
      }

      const result = await this.$dialog.prompt({
        title: '删除作品',
        content: '作品删除后无法恢复，请谨慎操作。<br>' +
          '输入作品名称<b class="mx-2 text-pre-wrap">' + book.title + '</b>确认删除',
        placeholder: '作品名称'
      })
      if (!result) {
        return
      }
      if (result.split(/\s/).join('') !== book.title.split(/\s/).join('')) {
        this.$alert.error('作品名称不匹配，如有特殊字符，可以尝试先修改作品名称')
        return
      }
      if (book.aid) {
        await this.$req.delete('/jianxiang/api/1/albums/' + book.aid + '/')
      } else if (book.sid) {
        await this.$req.post(`/blogbook/books/${book.id}/`, {deleted: 1})
      } else {
        const {sourceId, bookType, sourceTypeId} = book
        await this.$req.post('/api/book/del_book', {sourceId, bookType, sourceTypeId})
      }
      this.$alert.success('作品已删除')
      const index = this.books.indexOf(book)
      if (!this.bookType) {
        const results = await this.fetchBooks({bookType: book.bookType, size: 1, page: 1})
        if (results && results.length) {
          const newBook = results[0]
          newBook.totalCount = results.totalCount
          this.books.splice(index, 1, newBook)
        }
      } else {
        this.books.splice(index, 1)
      }
    }
  }
}
</script>

<style>
body[data-page="books"] {
  overflow-y: scroll;
}
</style>

<style lang="scss" scoped>
.books {
  .guide {
    padding-left: 2rem;
  }

  .book-header {
    font-size: 16px;
    font-weight: bold;
  }

  img.placeholder {
    width: 420px;
    max-width: 100%;
    @include media-breakpoint-down(sm) {
      margin-left: -6%;
      max-width: 80%;
    }
  }

  .card-intro {
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;

    .carousel {
      background-color: #f2f2f2;

      &:after {
        content: '';
        display: block;
        padding-bottom: 80%;
      }

      .swiper-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .guide {
    @include media-breakpoint-up(lg) {
      margin-top: 3em;
    }
  }

  .guide {
    display: inline-block;

    img {
      width: 309px;
    }
  }

  .notice {
    &:empty {
      display: none;
    }
  }
}
</style>
