<template>
  <div class="ad-mask">
    <popup v-if="!!selected" @close="onClose">
      <b-link :href="selected.url" :disabled="!selected.url"
              class="d-block w-100" :style="{maxWidth: selected.width}">
        <square height="auto" :src="selected.pic" shadow/>
      </b-link>
    </popup>
  </div>
</template>

<script>
import { chain, property, sample } from 'lodash'

export default {
  name: 'adMask',
  props: ['value'],
  data() {
    return {
      selected: null
    }
  },
  async created() {
    const expires = this.$ls.get('ad.expires')

    if (parseInt(expires) > Date.now()) {
      return
    }

    const candidates = []

    try {
      const [promotion, coupons] = await Promise.all([
        this.$ajax.fetchShelfConfig(),
        this.$ajax.fetchCoupons()
      ])

      let {popup, popupNewbie, popupCoupon} = promotion
      popup = popup.filter(i => {
        if (!i.start && !i.end) {
          return true
        }
        return this.$day().isBetween(i.start, i.end) && i.pic
      })

      const priors = popup.filter(i => i.first)
      if (priors.length) {
        candidates.push(...priors)
        return
      }

      if (!candidates.length && popupCoupon.length && coupons.length) {
        const ids = coupons.map(property('activity.id')).map(Number)
        const results = popupCoupon.filter(i => {
          return chain(i.ids).split(',').map(Number).intersection(ids).value().length > 0
        })

        if (results.length) {
          candidates.push(...results)
          return
        }
      }

      if (this.isNewbie && popupNewbie.pic) {
        candidates.push(popupNewbie)
        return
      }

      if (popup.length) {
        candidates.push(...popup)
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.selected = sample(candidates)
    }
  },
  methods: {
    onClose() {
      if (this.selected) {
        this.$ls.set('ad.expires', this.$day().add(6, 'hour').startOf('hour').valueOf())
      }
      this.selected = null
    }
  }
}
</script>
