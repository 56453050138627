<template>
  <div class="announcement" v-if="announcements.length > 0">
    <b-card>
      <b-link class="close" @click="dismiss">
        <fa icon="times"/>
      </b-link>
      <swiper :options="swiperOption" @transitionStart="onAutoplay">
        <swiper-slide v-for="item in announcements" :key="item.title">
          <b-row align-v="center" style="height: 100%;">
            <b-col cols="auto" class="text-primary">
              <fa :icon="item.icon || 'exclamation-circle'" fad fw size="2x"/>
            </b-col>
            <b-col>
              <div class="title">{{item.title}}</div>
              <div class="content">
                <span v-html="item.content"></span>
                <b-link v-bind="item.link.props" v-if="item.link"
                        @click.native="onClickLink">{{item.link.title}}
                </b-link>
              </div>
            </b-col>
          </b-row>
        </swiper-slide>
      </swiper>

      <div class="progress" :class="{active}">
        <div class="progress-bar"
             :style="{'animation-duration': swiperOption.autoplay.delay + 'ms'}"></div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { shuffle } from 'lodash'

export default {
  name: 'announcement',
  mixins: [require('@/mixins/swiper').default],
  data() {
    return {
      announcements: [],
      active: false,
      swiperOption: {
        autoHeight: true,
        watchOverflow: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        }
      }
    }
  },
  created() {
    if (this.$ss.getItem('announcementDismissed')) {
      // 关闭后本次会话不再显示公告
      return
    }

    this.init()
  },
  methods: {
    async init() {
      const result = await Promise.all([
        this.$ajax.fetchShelfConfig(),
        this.$ajax.fetchCoupons()
      ])

      this.announcements = []

      const broadcasts = result[0]
        .broadcasts
        .filter(item => new Date(item.startedAt) <= new Date())
        .filter(item => new Date(item.expiredAt) > new Date())
        .filter(item => item.content)

      this.announcements.push(...shuffle(broadcasts))

      const expireCouponCount = result[1]
        .filter(i => this.$day().add(5, 'days').isAfter(i.expireAt))
        .length
      if (expireCouponCount) {
        this.announcements.push({
          icon: 'ticket',
          link: {
            title: '点击查看',
            props: {
              to: '/my/coupons'
            }
          },
          title: '未使用的优惠券',
          content: '您有 ' + expireCouponCount + ' 张优惠券即将过期'
        })
      }

      if (this.announcements.length > 1) {
        this.swiperOption.loop = true
        this.initProgress()
      }
    },
    onAutoplay() {
      this.initProgress()
    },
    dismiss() {
      this.announcements = []
      this.$ss.setItem('wxbook.announcementDismissed', 1)
      this.$destroy()
      this.$el.remove()
    },
    initProgress() {
      this.active = false
      this.$nextTick(() => {
        this.active = true
      })
    },
    onClickLink() {
      this.$gtag.event('click_announcement')
      this.dismiss()
    }
  }
}
</script>

<style lang="scss" scoped>

.card {
  overflow: hidden;
}

.title {
  font-size: 1.25em;
  font-weight: bold;
}

.close {
  cursor: pointer;
  height: 2em;
  width: 2em;
  line-height: 2;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 2;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &.active {
    .progress-bar {
      animation-name: progress;
    }
  }

  .progress-bar {
    height: 2px;
    background-color: $primary;
    animation-timing-function: linear;
    width: 0;
  }
}
</style>
