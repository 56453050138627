module.exports = {
  wxbook: {
    pageCount: 726,
    totalLikes: 1595,
    id: 1100580,
    title: '“依诺”千金-宝贝成长日记',
    fromDate: '2017-12-31T16:00:00Z',
    type: 'A5-1',
    updateTime: 0,
    views: 40861,
    bookType: 'wxbook',
    pages: 752,
    author: '泡妈',
    cover: {
      pic: 'https://img.xinshu.me/upload/oi7rhjomScB3RmTPcPASV4nNWO2k-1511233377035-ff569eb36',
      editable: true,
      codeName: 'cover-44'
    },
    createTime: '2017-08-30T08:02:21Z',
    toDate: '2020-05-31T16:00:00Z',
    bookId: 'MTEwMDU4MDp3eGJvb2s6dm9ueWJhb2Jhb0Ax'
  },
  albums: {
    aid: 'ak5wo3c98b5kaw',
    uid: 'oVDOjtwSArR_PictIHZ2Ab4nQo3I',
    tid: 'xcalbum',
    name: '你是我的心肝宝贝',
    innerStyle: {name: '儿童', id: 'a4-child'},
    cover: {
      title: '一个人想静静的杂志册6',
      pic: 'https://img.xinshu.me/upload/ddd08cddcd4441699a1a8aa8728a4714',
      codeName: 'cover-wm-calbum.magazine2'
    },
    updateTime: 0,
    minPageCount: 24,
    maxPageCount: 24,
    pageCount: 24,
    uploadedImagesCount: 107,
    title: '你是我的心肝宝贝',
    id: 'ak5wo3c98b5kaw',
    bookType: 'albums',
    bookId: 'YWs1d28zYzk4YjVrYXc6YWxidW1zOm9WRE9qdHdTQXJSX1BpY3RJSFoyQWI0blFvM0k',
    pages: 24,
    type: '儿童'
  }
}
